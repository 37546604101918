import React, { useRef } from 'react';
import { Link, LinkProps } from '@leafwell/components';
import Image from 'next/image';

const ArrowButton: React.FC<
  LinkProps & {
    __typename?: string;
    classNameImage?: string;
    filled?: 'light' | 'purple' | null;
    imageBig?: boolean;
  }
> = ({
  className,
  classNameImage,
  loading,
  imageBig,
  title,
  filled = null,
  ...props
}) => {
  const isFilled = filled !== null;
  const imgEl = useRef<HTMLImageElement>(null);

  const handleHover = () => {
    if (filled === 'purple' && imgEl?.current) {
      imgEl?.current?.setAttribute('src', '/arrow-white.svg');
    }
  };

  const handleUnhover = () => {
    if (filled === 'purple' && imgEl?.current) {
      imgEl?.current?.setAttribute('src', '/arrow.svg');
    }
  };

  return (
    <Link
      {...props}
      className={[
        'group inline-grid grid-flow-col gap-x-2 justify-start items-center text-sm',
        isFilled ? 'rounded-md min-h-50 py-3 px-6 hover:no-underline' : '',
        filled === 'purple'
          ? 'border bg-lilac-300 border-lilac-300 hover:border-lilac-500 hover:bg-lilac-500 hover:text-white'
          : '',
        filled === 'light' ? 'hover:bg-lilac-50' : '',
        className,
      ].join(' ')}
      loading={loading}
      variant="underlineHover"
      onMouseOver={() => handleHover()}
      onMouseOut={() => handleUnhover()}
    >
      {title}
      <Image
        ref={imgEl}
        width={imageBig ? 20 : 15}
        height={imageBig ? 15 : 12}
        src="/arrow.svg"
        alt=""
        className={[
          loading ? 'opacity-0' : 'duration-500',
          classNameImage,
        ].join(' ')}
      />
    </Link>
  );
};

export default ArrowButton;
